import { createRoot } from "react-dom/client";

function importBuildTarget() {
  // source: https://github.com/phunkren/multiple-entry-points
  // https://blog.logrocket.com/creating-multiple-entry-points-create-react-app-without-ejecting/

  if (process.env.REACT_APP_BUILD_TARGET === "ai4xm") {
    return import("./AI4XM");
  } else if (process.env.REACT_APP_BUILD_TARGET === "ai4vocab") {
    return import("./AI4Vocab");
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  createRoot(document.getElementById("root") as HTMLElement).render(
    <Environment />
  )
);
